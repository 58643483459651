import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

const Home = lazy(() => import('./pages/Home'));
const Cart = lazy(() => import('./pages/Cart'));


function App() {
  return (
    <div className="App">
       <Router>
       <Suspense fallback={<div>Loading..</div>}>
        <Header/>
         <Routes>
           <Route path='' element={<Home />} />
           <Route path='/cart' element={<Cart />} />
         </Routes>

          
         <Footer/>
         </Suspense>
       </Router>
   
    </div>
  );
}

export default App;
