import React from 'react';
import {Link} from 'react-router-dom';
import {Sprite} from '../../assets/';
import './BtnCart.scss'

function BtnCart() {
  return (
   
    <Link to='/cart' className="cart__btn">
    <span className="cart__coast">29522 ₴</span>
    <span className="cart__total"> <svg className="cart__icon"><use href={Sprite + '#cart'}></use></svg> 3 </span>
   </Link>

  )
}

export default BtnCart