import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../assets';
import BtnCart from '../BtnCart';
import TopNav from '../TopNav';

import './Header.scss'

function Header() {
  return (
    <header>
        <div className="header__top">
            <div className="container container__top">
               
              <Link to='/' className='logo'>
                   <img src={Logo} alt="Skandi" lading='lazy' width='134' height='57' />
              </Link> 

               <TopNav/>

              <BtnCart/>
              
            </div>
        </div>
    </header>
  )
}

export default Header