import React from 'react';
import './TopNav.scss'

function TopNav() {
  return (
        <nav className='header__nav'>
            <ul className='header__nav_list'>
            <li className='nav__item'><a href="#new">Меню</a></li>
            <li className='nav__item'><a href="#pizza">Пицца</a></li>
            <li className='nav__item'><a href="#asia">Японское меню</a></li>
            <li className='nav__item'><a href="#contacts">Контакты</a></li>
            </ul>
        </nav>
  )
}

export default TopNav