import React from 'react';
import { Link } from 'react-router-dom';
import { Sprite } from '../../assets';
import './Footer.scss'




const currentYear = new Date().getFullYear();



function Footer() {
  return (
    

    <footer>
        <div className="container">
            <div className="footer__content">
                <div className="footer__item">
                    © 2014 - {currentYear} Skandi
                </div>
               

                <div className="footer__item">
                    <Link to="#" 
                          className="footer__link" 
                          target="_blank" 
                          title="Skandi Instagram">
                            Instagram
                    </Link>
             
                   <Link to="#"  
                        className="footer__link">
                            Вакансии
                    </Link>

                     <Link to="#"   
                        className="footer__link">
                            Есть вопросы?
                      </Link>
                </div>

                <div className="footer__item">
                    <svg className="footer__item_icon visa__icon"> <use href={Sprite + '#visa'}></use> </svg> 
                    <svg className="footer__item_icon master__icon"> <use href={Sprite + '#master'}></use></svg> 
                </div>

            </div>

        </div>
    </footer>






  )
}

export default Footer